// Build information, automatically generated by `ng-info`
const build = {
    version: "2.21.0",
    timestamp: "Wed Nov 13 2024 19:45:26 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "dd1dce",
        fullHash: "dd1dce4afe0766453126be84cb90aa304030faaa"
    }
};

export default build;