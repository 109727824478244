import {Component, OnInit} from '@angular/core';
import {OffersService} from '../../../services/offers/offers-api.service';
import {MerchantChannel, MerchantType, OffersMerchant} from '../../../domain/offers/merchant/offers-merchant';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthService} from '../../../services/auth.service';
import {LocationStatus, MerchantLocationStatus} from '../../../domain/offers/merchant/offers-merchant-create-request';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OffersMerchantUpdateRequest} from '../../../domain/offers/merchant/offers-merchant-update-request';
import {VerifiedMerchantLocationChannel} from '../../../domain/offers/merchant/verified-merchant-location';
import {MidTerminal, RewardProgramLocation} from '../../../domain/offers/reward-program/reward-program-location';
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-page-merchant-detail',
  templateUrl: './page-merchant-detail.component.html',
  styleUrls: ['./page-merchant-detail.component.css']
})
export class PageMerchantDetailComponent implements OnInit {
  public readonly MerchantChannel = MerchantChannel;

  // Data
  uuid: string;
  obj: OffersMerchant;
  channelOptions = MerchantChannel.getOptions();
  googlePlaceApiOptions = {
    componentRestrictions: {
      country: 'US'
    }
  }
  simplifiedLocationList: RewardProgramLocation[] = []

  // Visual
  loading = true;
  showEditName = false;
  newRewardProgramDisable = true;
  showAddLocationForm = false;
  addLocationForm: FormGroup;
  showEditLocationForm = false;
  editLocationForm: FormGroup;
  showAddTerminalForm = false;
  addTerminalForm: FormGroup;
  locationChannelOptions = VerifiedMerchantLocationChannel.getOptions();
  useAddressOverLatLng = true;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private offersService: OffersService,
              private confirmationService: ConfirmationService,
              private errorDisplayService: ErrorDisplayService,
              private authService: AuthService,
              private messageService: MessageService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadData(params['uuid']);
    });
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.newRewardProgramDisable = !result);

    this.initAddLocationForm();
    this.initEditLocationForm();
    this.initAddTerminalForm();
  }

  // Initial API Calls
  loadData(uuid: string) {
    this.uuid = uuid;
    this.loading = true;
    this.offersService.getMerchant(uuid).subscribe(result => {
      this.updateObjAndLocationList(result);
      this.locationChannelOptions = this.locationChannelOptions.filter(channel => (result.channel !== 'OL_STO') ? (channel === result.channel) : true);
      if (this.locationChannelOptions.length === 1) {
        this.getAddLocationFormLocations.at(0).patchValue({channel: this.locationChannelOptions[0]});
      }
      this.loading = false;
    });
  }

  // make sure to update location list on every update
  updateObjAndLocationList(merchant: OffersMerchant) {
    this.obj = merchant;
    this.simplifiedLocationList = this.simplifyLocationsList();
  }

  simplifyLocationsList(): RewardProgramLocation[] {
    if (!this.obj?.rewardProgram?.locations) {
      return [];
    }
    const allRpLocations = this.obj.rewardProgram.locations;
    // filter out locations that aren't related
    const relatedLocations = allRpLocations?.filter(rpLoc =>
      rpLoc.terminals?.find(t => t.mid=== this.obj.merchantIdentifier)
    );
    // remove terminals that aren't related
    return relatedLocations.map(rpLoc => {
      rpLoc.terminals = rpLoc.terminals?.filter(t => t.mid=== this.obj.merchantIdentifier);
      return rpLoc;
    });
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this merchant?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading = true;
        this.offersService.deleteMerchant(this.uuid).subscribe(result => {
          this.loading = false;
          this.location.back();
        }, error => {
          this.loading = false;
        })
      },
      reject: () => {
        console.log('Cancelled merchant deletion')
      }
    });
  }

  displayDelete() {
    switch (this.obj?.type) {
      case MerchantType.MID:
        if (!this.obj.rewardCampaigns || !this.obj.rewardCampaigns?.find(obj => !obj.removed)) {
          return true;
        }
        break;
      case MerchantType.BRAND:
        if (!this.obj.rewardProgram || this.obj.rewardProgram?.removed) {
          return true;
        }
        break;
    }

    return false;
  }

  createRewardsProgram() {
    const expirationPeriod = 365;
    const description = `<ul><li>Rewards can be earned and spent at any ${this.obj.name} locations.</li><li>If not spent within ${expirationPeriod} days, rewards will expire.</li></ul>`;
    const request = {brandId: this.uuid, title: `${this.obj.name} Loop`, expirationPeriod, description};
    this.offersService.createRewardProgram(request).subscribe((rp) => {
      this.obj.rewardProgram = rp;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Create Rewards Program', error);
    });
  }

  handleVerifiedChange(event) {
    this.updateMerchant({verified: event.checked})
  }

  handleGiftOptionChange(event) {
    this.updateMerchant({giftOption: event.checked})
  }

  updateName(name) {
    this.updateMerchant({name})
    this.showEditName = false;
  }

  updateAlias(alias) {
    this.updateMerchant({alias});
  }

  updateIdentifier(merchantIdentifier) {
    this.updateMerchant({merchantIdentifier});
  }

  updatePreviewText(previewText) {
    this.updateMerchant({previewText});
  }

  updateEndpoint(endpoint) {
    this.updateMerchant({endpoint});
  }

  updateChannel(channel) {
    const request: OffersMerchantUpdateRequest = {};
    request.channel = channel;

    // clear locations if changing to ONLINE, clear endpoint if changing to IN_STORE
    switch (channel) {
      case MerchantChannel.IN_STORE:
        if (this.obj?.endpoint.length > 0) {
          request.endpoint = '';
        }
        break;
    }

    this.updateMerchant(request);
  }

  saveAvatar(avatar) {
    this.updateMerchant({avatar})
  }

  saveImage(image) {
    this.updateMerchant({image})
  }

  updateMerchant(request) {
    this.loading = true;
    return this.offersService.updateMerchant(this.uuid, request).subscribe(result => {
      this.obj = result;
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  allowVerifyToggle() {
    if (this.obj?.type === MerchantType.MID) {
      if (this.obj?.channel === MerchantChannel.ONLINE) {
        return false;
      } else {
        return !this.obj?.merchantIdentifier;
      }
    } else {
      return false;
    }
  }

  // Location

  get hasOnlineLocation() {
    return !!this.simplifiedLocationList.find(loc => loc.channel === VerifiedMerchantLocationChannel.ONLINE);
  }

  get hasOnlineLocationPending() {
    return !!this.getAddLocationFormLocations?.value.find(loc => loc.channel === VerifiedMerchantLocationChannel.ONLINE);
  }

  get disableChannelDropdown() {
    return (this.hasOnlineLocationPending && this.getAddLocationFormLocations.value.length > 1)
      || this.hasOnlineLocation
      || this.locationChannelOptions.length === 1;
  }

  getNameFromLocation(location: RewardProgramLocation) {
    if (location.channel === VerifiedMerchantLocationChannel.ONLINE) {
      return location.alias || location.endpoint || 'Online Location';
    } else if (location.channel === VerifiedMerchantLocationChannel.IN_STORE) {
      return location.alias || location.address || 'Store Location';
    }
  }

  showAddLocationFormDialog() {
    if (this.obj?.channel === 'ONLINE' && this.hasOnlineLocation) {
      this.messageService.add({severity: 'error', summary:`Cannot Add More Locations`, detail:'Only 1 ONLINE location is allowed'});
      return
    }
    this.showAddLocationForm = true
  }

  deleteLocationFromAddLocationForm(locationIndex: number) {
    this.getAddLocationFormLocations.removeAt(locationIndex);
  }

  deleteTerminalFromAddLocationForm(locationIndex: number) {
    const terminalControlLength = this.getAddLocationFormTerminals(locationIndex).length;
    if (terminalControlLength > 1) {
      this.getAddLocationFormTerminals(locationIndex).removeAt(terminalControlLength - 1);
    }
  }

  mapRplToLs(rpl: RewardProgramLocation) {
    return rpl.terminals.map(terminal => {
      const data: LocationStatus = {
        channel: rpl.channel as MerchantLocationStatus,
        status: MerchantLocationStatus.ACTIVE,
        latitude: rpl.latitude,
        longitude: rpl.longitude,
        alias: rpl.alias,
        terminalId: terminal.terminal,
        endpoint: rpl.endpoint,
      };
      data.status = terminal.status ? terminal.status : MerchantLocationStatus.ACTIVE;
      if (terminal.verifiedLocationId) {
        data.id = terminal.verifiedLocationId;
      }
      return data;
    });
  }

  saveAddLocationForm() {
    this.loading = true;
    const request: OffersMerchantUpdateRequest = {};
    request.locations = [];
    (this.getAddLocationFormLocations.value as RewardProgramLocation[]).forEach(rpl => {
      request.locations.push(...this.mapRplToLs(rpl));
    });
    this.offersService.updateMerchant(this.uuid, request).subscribe(result => {
      this.updateObjAndLocationList(result);
    }, error => {
      this.errorDisplayService.displayErrorResponse('Manage Location', error);
    }, () => {
      this.loading = false;
    });
  }

  handleAddLocationFormSubmit() {
    this.saveAddLocationForm();
    this.showAddLocationForm = false;
  }

  initAddLocationForm() {
    this.addLocationForm = this.fb.group({
      locations: this.fb.array([this.addLocationFormGroup()])
    });
  }

  initEditLocationForm() {
    this.editLocationForm = this.addLocationFormGroup();
  }

  updateAddLocationForm(locations: RewardProgramLocation[]) {
    this.addLocationForm = this.fb.group({
      locations: this.fb.array(locations.map(location => this.addLocationFormGroup(location)))
    });
  }

  updateEditLocationForm(location: RewardProgramLocation) {
    this.editLocationForm = this.addLocationFormGroup(location);
  }

  get getAddLocationFormLocations() {
    return this.addLocationForm?.get('locations') as FormArray;
  }

  getAddLocationFormTerminals(locationIndex) {
    return (this.getAddLocationFormLocations.at(locationIndex) as FormGroup).get('terminals') as FormArray;
  }

  addTerminalFormGroup(terminalObj: MidTerminal = null): FormGroup {
    return this.fb.group({
      terminal: [terminalObj ? terminalObj.terminal : '', [Validators.required]],
      verifiedLocationId: [terminalObj ? terminalObj.verifiedLocationId : ''],
      status: MerchantLocationStatus.ACTIVE,
    });
  }

  addLocationFormGroup(location: RewardProgramLocation = null): FormGroup {
    const _formGroup = this.fb.group({
      channel: [location ? location.channel : '', [Validators.required]],
      terminals: this.fb.array(location
        ? location.terminals.map(t => this.addTerminalFormGroup(t))
        : [this.addTerminalFormGroup()]),
      latitude: [location ? location.latitude : ''],
      longitude: [location ? location.longitude : ''],
      endpoint: [location ? location.endpoint : ''],
      placeId: [location ? location.placeId: ''],
      brandLocationId: [location ? location.brandLocationId : ''],
      address: [location ? location.address : ''],
      alias: [location ? location.alias : ''],
    });

    if (!location && this.locationChannelOptions.length === 1) {
      _formGroup.patchValue({channel: this.locationChannelOptions[0]});
    } else if (!location && (this.hasOnlineLocationPending || this.hasOnlineLocation)) {
      _formGroup.patchValue({channel: VerifiedMerchantLocationChannel.IN_STORE})
    }

    if (this.hasOnlineLocationPending && this.obj?.channel === MerchantChannel.OL_STO) {}

    _formGroup.controls['channel']?.valueChanges.subscribe(value => {
      if (value === 'IN_STORE') {
        _formGroup.controls['latitude']?.setValidators([Validators.required]);
        _formGroup.controls['longitude']?.setValidators([Validators.required]);
        _formGroup.controls['endpoint']?.setValidators([]);
        _formGroup.patchValue({
          endpoint: ''
        })
      } else {
        _formGroup.controls['latitude']?.setValidators([]);
        _formGroup.controls['longitude']?.setValidators([]);
        _formGroup.controls['endpoint']?.setValidators([Validators.required]);
        _formGroup.patchValue({
          longitude: '',
          latitude: ''
        })
      }
      _formGroup.controls['latitude']?.updateValueAndValidity();
      _formGroup.controls['longitude']?.updateValueAndValidity();
      _formGroup.controls['endpoint']?.updateValueAndValidity();
    });
    return _formGroup;
  }

  addLocationToAddLocationForm() {
    this.getAddLocationFormLocations.push(this.addLocationFormGroup());
    this.addLocationForm.markAsDirty();
  }

  addTerminalToAddLocationForm(i) {
    this.getAddLocationFormTerminals(i).push(this.addTerminalFormGroup());
    this.addLocationForm.markAsDirty();
  }

  clearAddLocationsForm() {
    const max = this.getAddLocationFormLocations.value.length;
    for (let i = 0; i < max; i++) {
      this.deleteLocationFromAddLocationForm(0);
    }
    this.addLocationToAddLocationForm();
  }

  clearEditLocationForm() {
    this.editLocationForm = this.addLocationFormGroup();
  }

  handleLocationModalClose() {
    if (this.addLocationForm.dirty) {
      this.clearAddLocationsForm();
    }
    this.useAddressOverLatLng = true;
  }

  handleAddressChange(event, formElementIndex = null) {
    if (formElementIndex !== null) {
      this.getAddLocationFormLocations.at(formElementIndex).patchValue({
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
        address: event.formatted_address
      });
    } else {
      this.editLocationForm.patchValue({
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
        address: event.formatted_address,
      })
      this.editLocationForm.markAsDirty();
    }
  }

  handleRemoveLocation(location: RewardProgramLocation) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete <span class="font-weight-bold">${this.getNameFromLocation(location)}</span> and all its Terminals?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.updateAddLocationForm(this.simplifiedLocationList);
        const locIndex = this.getAddLocationFormLocations.controls.findIndex(
          (loc: FormGroup) => loc.get('brandLocationId').value === location.brandLocationId
        );
        ((this.getAddLocationFormLocations.at(locIndex) as FormGroup).controls.terminals as FormArray).controls.forEach(terminalGroup => {
          terminalGroup.patchValue({
            status: MerchantLocationStatus.REMOVED
          })
        })
        this.saveAddLocationForm();
        this.clearAddLocationsForm();
      }
    });
  }

  displayEditLocationForm(loc: RewardProgramLocation) {
    this.updateEditLocationForm(loc);
    this.showEditLocationForm = true;
  }

  handleEditLocationSubmit() {
    this.updateAddLocationForm(this.simplifiedLocationList);
    this.getAddLocationFormLocations.controls.forEach((loc: FormGroup) => {
      if (loc.get('brandLocationId').value === this.editLocationForm.get('brandLocationId').value) {
        const data: LocationStatus = {};
        data.alias = this.editLocationForm.get('alias').value;
        if (this.editLocationForm.get('endpoint').value) {
          data.endpoint = this.editLocationForm.get('endpoint').value;
        }
        loc.patchValue(data);
      }
    });
    this.saveAddLocationForm();
    this.showEditLocationForm = false;
    this.clearAddLocationsForm();
    this.clearEditLocationForm();
  }

  handleRemoveTerminalId(terminalObj: MidTerminal, location: RewardProgramLocation) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete terminal <span class="font-weight-bold">${terminalObj.terminal}</span> from <span class="font-weight-bold">${this.getNameFromLocation(location)}</span>?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.updateAddLocationForm(this.simplifiedLocationList);
        this.getAddLocationFormLocations.controls.forEach((loc: FormGroup) => {
          const i = loc.get('terminals').value.findIndex(t => t.terminal === terminalObj.terminal)
          if (i !== -1) {
            (loc.get('terminals') as FormArray).at(i).patchValue({
              status: MerchantLocationStatus.REMOVED
            })
          }
        });
        this.saveAddLocationForm();
        this.clearAddLocationsForm();
      }
    });
  }

  //
  // Add Terminal Form
  //

  initAddTerminalForm() {
    this.addTerminalForm = this.fb.group({
      brandLocationId: '',
      terminals: this.fb.array([this.addTerminalFormGroup()])
    });
  }

  displayEditTerminalForm(brandLocationId: string) {
    this.addTerminalForm.patchValue({
      brandLocationId,
    });
    this.showAddTerminalForm = true;
  }

  clearAddTerminalForm() {
    const max = this.getAddTerminalFormTerminals.value.length;
    for (let i = 0; i < max; i++) {
      this.deleteTerminalFromAddTerminalForm();
    }
    this.addTerminalToAddTerminalForm();
  }

  handleAddTerminalModalClose() {
    if (this.addTerminalForm.dirty) {
      this.clearAddTerminalForm();
    }
  }

  get getAddTerminalFormTerminals() {
    return this.addTerminalForm?.get('terminals') as FormArray;
  }

  addTerminalToAddTerminalForm() {
    this.getAddTerminalFormTerminals.push(this.addTerminalFormGroup())
    this.addTerminalForm.markAsDirty();
  }

  deleteTerminalFromAddTerminalForm() {
    const terminalControlLength = this.getAddTerminalFormTerminals.length;
    this.getAddTerminalFormTerminals.removeAt(terminalControlLength - 1);
  }

  handleAddTerminalFormSubmit() {
    this.updateAddLocationForm(this.simplifiedLocationList);
    this.getAddLocationFormLocations.controls.forEach((loc: FormGroup) => {
      if (loc.get('brandLocationId').value === this.addTerminalForm.get('brandLocationId').value) {
        // @ts-ignore
        for (const terminal of this.addTerminalForm.get('terminals').controls) {
          (loc.get('terminals') as FormArray).push(terminal);
        }
      }
    });
    this.saveAddLocationForm();
    this.showAddTerminalForm = false;
    this.clearAddLocationsForm();
    this.clearAddTerminalForm();
  }
}
