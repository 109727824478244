export const environment = {
  production: false,
  envName: 'dev',
  merchantUIOrigin: 'http://merchant.internal.gane.io',
  adminUIOrigin: 'https://backoffice-dev.simplytapp.com',
  billingUrl: 'https://payments-dev.ovloop.com/billing/v1',
  platformBillingApiUrl: 'https://api-dev.ovloop.com/billing/v1',
  consumersApiUrl: 'https://api-dev.ovloop.com/consumer/v1',
  enterpriseApiUrl: 'https://api-dev.ovloop.com/enterprise/v1',
  affiliateApiUrl: 'https://api-dev.ovloop.com/affiliate/v1',
  hapiUrl: 'https://payments-dev.ovloop.com/houston-api',
  offersUrl: 'https://payments-dev.ovloop.com/web-offers-api',
  paymentsUrl: 'https://payments-dev.ovloop.com/payment/v1',
  sherlockUrl: 'https://payments-dev.ovloop.com/web-sherlock-api',
  stapiUrl: 'https://dev.simplytapp.com/st-api',
  uidUrl: 'https://payments-dev.ovloop.com/uid/v1',
  walletUrl: 'https://payments-dev.ovloop.com/wallet/v1',
  cVaultUrl: 'https://payments-dev.ovloop.com/cvault/v1',
  decafUrl: '/decaf',
  concrConfigUrl: 'https://api-dev.ovloop.com/concr-config/v1',
  wcWrapperUrl: 'https://api-dev.ovloop.com/wc-wrapper/v1',
  checkoutUrl: 'https://payments-dev.ovloop.com/checkout/v1',
  loansEnabled: true,
  auth: {
    clientID: '3XJn4uIcJhThaaypUXv7q2OgpKkMpv2K',
    domain: 'ovloop-ap-dev.us.auth0.com', // e.g., https://you.auth0.com/
    audience: 'https://api-dev.ovloop.com', // e.g., http://localhost:3001
    redirect: 'https://backoffice-dev.simplytapp.com/auth0/login',
    scope: 'openid profile email'
  }
};
